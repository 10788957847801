import React from 'react'
import PropTypes from 'prop-types'
// import Helmet from 'react-helmet'
import 'intl'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.min.css'
import '../../fonts/fonts.css'

const theme = {
  textColor: '#000',
  textColorWhite: '#fff',
  textColorBlack: '#000',
  textColorOrange: '#eeaa31',
  textColorGray: '#727272',
  textColorGrayLight: '#8a8a8a',

  colorWhite: '#fff',
  colorBlack: '#000',
  colorBlueDark: '#031123',
  colorOrange: '#eeaa31',
  colorOrangeDark: '#e09904',
  colorGrayLight: '#dfdbd7',

  fontMontserrat: `'Montserrat', sans-serif`,
  fontRobotoCondensed: `'Roboto Condensed', sans-serif`,
  fontPlayfairDisplay: `'Playfair Display', serif`,
  fontHelveticaCondensed: `'Helvetica Neue LT Pro Condensed', sans-serif`,
}

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
  }
  body {
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${theme.textColor};
    font-family: ${theme.fontMontserrat};
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 17px;
    }
  }

  a {
    transition: ease 0.2s all;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .container {
    @media (min-width: 1600px) {
      max-width: 1280px;
    }
  }
`

const IndexPageLayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${theme.colorBlueDark};
`

const Layout = (props) => (
  <>
    <GlobalStyle />

    <ThemeProvider theme={theme}>
      <IndexPageLayoutWrapper>{props.children}</IndexPageLayoutWrapper>
    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
